import React, { useState } from "react";

import { Spinner } from "../../components/Spinner";
import useApi from "../../utils/useAPI";

const RepSalesEntry = ({ repID, repOffering, routeHeaderPress, selectedOffering, singleRoute }) => {
    // const productKey = repOffering.repSecondary.replace("offering-", "");
    const isSelected = selectedOffering?.offeringName == repOffering?.offeringName || singleRoute;

    const [emailAddress, setEmailAddress] = useState("");
    const [error, setError] = useState("");
    const { loading, data, error: apiError, trigger } = useApi();

    const updateEmailAddress = (e) => {
        setError("");
        setEmailAddress(e.target.value);
    }

    const submitRequest = () => {
        var re = /\S+@\S+\.\S+/;
        if (emailAddress === "" || !re.test(emailAddress)) {
            setError("Please enter a valid email address");
            return;
        }

        const url = `reps/sales-entry`
        const postData = {
            email: emailAddress,
            repID,
            postedOffering: repOffering
        }
        trigger(url, postData);
    }

    return <div className="rep-offering">
        <div className="header" onClick={() => routeHeaderPress(isSelected ? null : repOffering)}>
            <h1 >{ repOffering.offeringName }</h1>
            { !singleRoute && <span className={`carat ${isSelected ? "selected" : ""}`}></span> }
        </div>
        { isSelected && <div className="offering-details">
            <span className="directions">
                Enter the guest&apos;s email address here. Instructions will be send to them.
            </span>
            <label>Email Address:</label>
            <input type={"text"} onChange={updateEmailAddress} />
            { error && <span className="error">{ error }</span> }
            { apiError && <span className="error">{ apiError }</span> }
            <button onClick={submitRequest}>Submit { loading && <Spinner /> }</button>
            { data === "success" && <span className="success">Success! User has been emailed instructions on how to access the route.</span>}
        </div>}
    </div>

}

export default RepSalesEntry;