import React, { useContext } from 'react';


import { parseStripeData, StripeOfferings } from "../utils/stripe";
import { getLangTerm } from '../lang';
import { SiteContext } from './Layout';
import PurchaseButton from './PurchaseButton';
import PoweredByStripe from "../images/svg/powered_by_stripe.svg";

export const formatPrice = (amount, currency) => {
    let price = (amount / 100).toFixed(2)
    let numberFormat = new Intl.NumberFormat(["en-CA"], {
        style: "currency",
        currency: currency || "CAD",
        currencyDisplay: "symbol",
    })
    return numberFormat.format(price)
}

const PurchaseButtons = ({route}) => {
    const {
        lang,
        langData,
        routeSlugs
    } = useContext(SiteContext);

    const routeLangData = langData[`${lang}-${route.slug}`];

    if (!route.isPremium) {
        return null;
    }

    return <div className="purchase-container">
        <StripeOfferings renderFunction={({data}) => {
            return <>
                <h2>{getLangTerm("purchase-route-now", lang).replace("||route||", routeLangData.title)}</h2>
                <ul className="purchase-buttons">
                    { data?.map((price, i) => {
                        const { id: priceID, product, unit_amount, currency } = price;
                        const { name, metadata, description } = product;
                        const { product_key, fr_title, fr_description } = metadata;
                        // console.log({ product, data })
                        const { targetOffering, fullPrice, savings } = parseStripeData({productKey : product_key, routeSlugs, stripeData: data});
                        // console.log({ targetOffering, fullPrice, savings })

                        if (route.siteOfferings.indexOf(product_key) > -1) {

                            const displayName = lang == "fr" && fr_title ? fr_title : name;
                            let displayDesc = lang == "fr" && fr_description ? fr_description : description;
                            displayDesc = displayDesc.replace("Gastown", "<b>Gastown</b>");
                            displayDesc = displayDesc.replace("Stanley Park", "<b>Stanley Park</b>");

                            return <li key={i}>
                                <div className="border-container" style={{backgroundColor: route.color}}></div>
                                <div className="purchase-details inner">
                                    <h3>{displayName}</h3>
                                    <p dangerouslySetInnerHTML={ {__html: displayDesc}}></p>
                                    { savings > 0 && <span className="savings-row">
                                        <span className="full-price">{ getLangTerm("regular-price", lang) }: {formatPrice(fullPrice, targetOffering.currency)}</span>
                                        <span className="save-routes">{ getLangTerm("save", lang) } { savings }%</span>
                                    </span>}
                                    <PurchaseButton priceID={priceID} name={displayName} >
                                        { getLangTerm("buy-now", lang) }: {formatPrice(unit_amount, currency)}
                                    </PurchaseButton>
                                </div>
                            </li>
                        }
                    })}
                </ul>
                <PoweredByStripe className="stripe" height="30" width="138"  />
            </>
        }} />
    </div>
}

export default PurchaseButtons;