import React, { useState } from "react";
import QRCode from "react-qr-code";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { formatPrice } from "../../components/PurchaseButtons";
import { parseStripeData } from "../../utils/stripe";

const RepOffering = ({ repID, repOffering, stripeData, routeSlugs, routeHeaderPress, selectedOffering, singleRoute }) => {

    const productKey = repOffering.repSecondary.replace("offering-", "");

    // console.log({stripeData})

    const { targetOffering, bundledOfferings, fullPrice, savings } = parseStripeData({productKey, routeSlugs, stripeData});
    // console.log({targetOffering})
    // console.log({fullPrice})
    // console.log({savings})
    const [copied, setCopied] = useState(null);

    const isSelected = selectedOffering?.id == targetOffering?.id || singleRoute;

    const refUrl = `${process.env.SALES_SITE_URL}offering/?repID=${repID}&productKey=${productKey}`;

    return <div className="rep-offering">
        <div className="header" onClick={() => routeHeaderPress(isSelected ? null : targetOffering)}>
            <h1>{ repOffering.offeringName }</h1>
            { !singleRoute && <span className={`carat ${isSelected ? "selected" : ""}`}></span> }
        </div>
        { isSelected && <div className="offering-details">
            { savings > 0 ? <div className="price-row">
                <span className="full-price">Regular Price: {formatPrice(fullPrice, targetOffering.currency)}</span> Buy Now: {formatPrice(targetOffering.unit_amount, targetOffering.currency)} <span className="savings">Save { savings }%</span>
            </div> : <>Price: { targetOffering && formatPrice(targetOffering.unit_amount, targetOffering.currency) }</> }
            <div className="includes-list"> Includes:
                <ul>
                    { bundledOfferings.map(bo => <li key={bo.id}>{bo.name}</li>) }
                </ul>
            </div>
            <span className="directions">
                Have your new friend scan the QR code to take them to the website. You can also click the link yourself or copy the url to your clipboard.
            </span>
            <QRCode className="qr" value={refUrl} />
            <a className="link" target="_blank" rel="noreferrer" href={refUrl}>Link to Offering Page</a>
            <CopyToClipboard text={refUrl}
                onCopy={() => setCopied(true)}>
                <span className="copy">Copy to clipboard for sharing</span>
            </CopyToClipboard>
            { copied && <span className="copied">Copied!!</span> }
        </div>}
    </div>
}

export default RepOffering;