import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
// const queryString = require('query-string');

import { Spinner } from "../../components/Spinner";
import { StripeOfferings } from "../../utils/stripe";
import useApi from '../../utils/useAPI';
import RepOffering from "./repOffering";
import RepSalesEntry from "./repSalesEntry";
import RepDefaultLinks from "./repDefaultLinks";
import { trackPageView, TrackingScript } from '../../components/Layout';

const Reps = ({pageContext}) => {
    const { routeSlugs } = pageContext;

    // const parsedQS = queryString.parse(location.search);
    // debugger;
    // const isSalesEntry = !!parsedQS.salesEntry;
    // const salesOfferingID = parsedQS.offeringID

    const [repID, setRepID] = useState("not-set");
    const { loading, data, error: apiError, trigger } = useApi();
    const [repOfferings, setRepOfferings] = useState([]);
    const [repInfo, setRepInfo] = useState(null);
    const [selectedOffering, setSelectedOffering] = useState(null);

    useEffect(() => {
        if (data?.items?.length > 0) {
            const tempOfferings = [];
            data.items.forEach(item => {
                if (item.repSecondary === "rep-info") {
                    setRepInfo(item);
                }
                if (item.offeringName) {
                    tempOfferings.push(item);
                }
            });
            tempOfferings.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                return 0;
            });
            setRepOfferings(tempOfferings);
        }
    }, [data])

    useEffect(() => {
        // debugger;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let repIDFromQuery = params.get('repID');
        setRepID(repIDFromQuery);
        if (repIDFromQuery) {
            const url = `reps/offerings?repID=${repIDFromQuery}`
            trigger(url);
        }
    }, [])

    const routeHeaderPress = (targetOffering) =>{
        setSelectedOffering(targetOffering); 
    }

    return <div className='rep-container'>
        <TrackingScript />
        { repID === null && <Link to={"/repRequest"}>Link to rep form</Link> }
        { repInfo !== null && <>
            <div className="rep-info">
            Welcome { repInfo.name }<br/>{ repInfo.emailAddress}
            </div>
            {/* { !selectedOffering && !isSalesEntry && <div className="desc">
                Click a route to show the details.
            </div> } */}
        </> }
        { loading && <Spinner /> }
        <StripeOfferings renderFunction={({data}) => {

            return <>
                { repOfferings && <div className="rep-offerings">
                    { repOfferings.length > 0 && <RepDefaultLinks routeHeaderPress={routeHeaderPress} selectedOffering={selectedOffering} repID={repID} /> }
                    { repOfferings.map(repOffering => {
                        // console.log("repOffering", repOffering)
                        if (repOffering.salesEntry) {
                            return <RepSalesEntry key={repOffering.repSecondary} singleRoute={repOfferings.length === 1} routeHeaderPress={routeHeaderPress} selectedOffering={selectedOffering} repID={repID} repOffering={repOffering} />
                        } else {
                            return <RepOffering key={repOffering.repSecondary} singleRoute={repOfferings.length === 1} routeHeaderPress={routeHeaderPress} selectedOffering={selectedOffering} repID={repID} repOffering={repOffering} stripeData={data} routeSlugs={routeSlugs} />
                        }
                    }) }
                </div>}
            </>
        }} />
        { apiError && <div>API Error: { apiError }</div> }
        { trackPageView() }
    </div>
};
export default Reps;