import React from "react";
import QRCode from "react-qr-code";

const RepDefaultLinks = ({ repID, routeHeaderPress, selectedOffering }) => {

    const isMainSelected = selectedOffering == "main-site";
    const isMapSelected = selectedOffering == "map";

    const mainRefUrl = `${process.env.SALES_SITE_URL}?repID=${repID}`;
    const mapRefUrl = `${process.env.MAP_URL}?repID=${repID}`;

    return <>
        <div className="rep-offering">
            <div className="header" onClick={() => routeHeaderPress(isMainSelected ? null : "main-site")}>
                <h2>Link to Main Site</h2>
                <span className={`carat ${isMainSelected ? "selected" : ""}`}></span>
            </div>
            { isMainSelected && <div className="offering-details">
                <span className="directions">
                    Goes to vanwalks.ca, with your referral
                </span>
                <QRCode className="qr" value={mainRefUrl} />
                <a className="link" target="_blank" rel="noreferrer" href={mainRefUrl}>Link to Main Site</a>
            </div>}
        </div>
        <div className="rep-offering">
            <div className="header" onClick={() => routeHeaderPress(isMapSelected ? null : "map")}>
                <h2>Link to Web App</h2>
                <span className={`carat ${isMapSelected ? "selected" : ""}`}></span>
            </div>
            { isMapSelected && <div className="offering-details">
                <span className="directions">
                    Goes to map.vanwalks.ca, with your referral
                </span>
                <QRCode className="qr" value={mapRefUrl} />
                <a className="link" target="_blank" rel="noreferrer" href={mapRefUrl}>Link to Web App</a>
            </div>}
        </div>
    </>
};
export default RepDefaultLinks;