import { useEffect, useState } from "react"

const useApi = () => {   
    
    const [url, setUrl] = useState("");
    const [postData, setPostData] = useState(null);
    const [triggerState, setTriggerState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const trigger = (url, postData = null) => {
        setUrl(url);
        setPostData(postData);
        setTriggerState(true);
    };

    const fetchApi = () => {
        // console.log("settings loading")
        setLoading(true);
        setError(null);
        setData(null);
        const apiEndpoint = `${process.env.VANWALKS_API}/${url}`;
        const apiKey = process.env.VANWALKS_API_KEY;
        const urlParams = {
            headers: {
                'Content-Type': 'application/json', // 'Content-Type': 'application/x-www-form-urlencoded',
                'x-api-key': apiKey
            },
            ...(postData !== null && {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(postData) // body data type must match "Content-Type" header
            })
        }
        fetch(apiEndpoint, urlParams)
            .then(async(response) => {
                const resJson = await response.json();
                if (response.status !== 200) {
                    throw Error(resJson.error);
                }
                return resJson;
            })
            .then(json => {
                // console.log(json);
                setLoading(false);
                if (json.error) {
                    setError(json.error)
                } else {
                    setError(null);
                    setData(json);
                }
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
                setError(e.message || "There was an error making the request");
            });
    };

    useEffect(() => {
        if (triggerState) {
            setTriggerState(false);
            fetchApi();
        }
    }, [triggerState]);

    return { loading, data, error, trigger }
};

export default useApi;