import React, { useState, useContext } from 'react';

import { Spinner } from './Spinner';
import { redirectToPurchase } from '../utils/stripe';
import { getSalesRepId } from "../utils/salesRep";
import { SiteContext } from './Layout';

const PurchaseButton = ({ priceID, name, children }) => {
    const {
        lang,
    } = useContext(SiteContext);

    const repID = getSalesRepId();

    const [selectedPurchase, setSelectedPurchase] = useState(null);

    return <button onClick={() => {
        window.plausible('PurchaseButtonClick', { props: { priceID, name, repID } });
        setSelectedPurchase(priceID)
        redirectToPurchase({priceID, name, lang})
    }}> 
        { children }
        { selectedPurchase && selectedPurchase == priceID && <Spinner /> }
    </button>
}
export default PurchaseButton;